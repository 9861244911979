'use client'

import { Children } from 'react'
import { useMachine } from '@xstate/react'
import { AnimatePresence, motion, Variant } from 'framer-motion'
import { assign, createMachine } from 'xstate'

interface CycleProps {
  children: React.ReactNode
  interval: number
  variants: Record<'on' | 'off', Variant>
}

const cycleMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QGECeBjANmAdAJTAEMJUBiAD1gBdCrdCAzOgJwAoBlAFQFEAFAfQCSAOR54AagEEAMgEpSaLLgLFUAbQAMAXUSgADgHtYASyrGDAO10hyiALQBGACwA2HE6fONLgEwBWABoQVEQPHABmcL8ATnCAdj8AX2SgiwMIOGtFbGtDEzNLa1sER2ind08nb38gkJKHP3LonwcADgSUkGzlIhJco1NzKyQbe2b3OJ8XP19A4PsXOPcW9qTkxKA */
    id: 'Cycle',

    tsTypes: {} as import('./Cycle.typegen').Typegen0,

    schema: {
      context: {} as { interval: number; steps: number; currentStep: number },
    },

    context: { currentStep: 0, interval: 1000, steps: 2 },
    initial: 'Ready',

    states: {
      Ready: {
        after: {
          STEP_INTERVAL: {
            target: 'Ready',
            actions: 'Increment current step',
          },
        },
      },
    },

    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    actions: {
      'Increment current step': assign(({ currentStep }) => ({ currentStep: currentStep + 1 })),
    },
    delays: {
      STEP_INTERVAL: ({ interval }) => interval,
    },
  },
)

export function Cycle({ children, interval, variants }: CycleProps) {
  const elements = Children.toArray(children)

  const [
    {
      context: { currentStep, steps },
    },
  ] = useMachine(cycleMachine, { context: { interval: interval * 1000, steps: elements.length, currentStep: 0 } })

  return (
    <AnimatePresence initial={false}>
      {elements.map((element, index) => (
        <motion.div
          key={index}
          initial="out"
          animate={currentStep % steps === index ? 'on' : 'off'}
          exit="out"
          variants={variants}
          className="absolute inset-0 flex aspect-square w-full items-center justify-center"
        >
          {element}
        </motion.div>
      ))}
    </AnimatePresence>
  )
}
