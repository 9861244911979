'use client'

import { Transition } from '@headlessui/react'
import ArrowLeftIcon from '@heroicons/react/20/solid/ArrowLeftIcon'
import ArrowRightIcon from '@heroicons/react/20/solid/ArrowRightIcon'
import { shallowEqual } from '@xstate/react'
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { Button } from '../Button'
import { StepperContext } from './StepperContext'

interface StepperNavigatorProps {
  className?: string
}

export function StepperNavigator({ className }: StepperNavigatorProps) {
  const [, send] = StepperContext.useActor()
  const { canGoToPreviousStep, step, maxSteps, showBackButton, change, hideNavigator } = StepperContext.useSelector(
    (state) => {
      const { step, maxSteps, change } = state.context
      const showBackButton = !!step
      const canGoToPreviousStep = !!step
      const hideNavigator = state.matches('Navigator.Hidden')
      return { canGoToPreviousStep, step, maxSteps, showBackButton, change, hideNavigator }
    },
    shallowEqual,
  )

  const backButtonVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { delay: 0.2 } },
    exit: { opacity: 0 },
  }

  const handleClickPrevious = () => {
    send('Scroll to previous step')
  }

  const handleClickNext = () => {
    send('Scroll to next step')
  }

  return (
    <motion.div
      layout
      className={twMerge(
        'z-10 mt-14 flex items-center justify-center gap-x-5 transition-opacity duration-500',
        hideNavigator ? 'pointer-events-none opacity-0' : 'pointer-events-auto opacity-100',
        className,
      )}
    >
      <LayoutGroup>
        <AnimatePresence presenceAffectsLayout mode="sync">
          {showBackButton && (
            <motion.div
              key="backButton"
              className="flex gap-x-5"
              layout="position"
              layoutId="backButton"
              variants={backButtonVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.5 }}
            >
              <Button
                aria-disabled={!canGoToPreviousStep}
                icon={<ArrowLeftIcon className="pointer-events-none h-5 w-5" />}
                className="relative flex aspect-square w-10 cursor-pointer items-center justify-center rounded-full bg-white/30 text-current ring-1 ring-current backdrop-blur backdrop-brightness-125 transition-opacity aria-disabled:pointer-events-none aria-disabled:opacity-0 aria-disabled:focus-visible:ring-0"
                onClick={handleClickPrevious}
              />
              <div
                className={twMerge(
                  'pointer-events-none hidden w-20 text-center font-display text-3.5xl tracking-wider md:grid md:grid-cols-3',
                  !canGoToPreviousStep && 'opacity-0',
                )}
              >
                <div className="relative grid grid-cols-1 grid-rows-1">
                  {Array.from({ length: maxSteps }, (_, index) => (
                    <Transition
                      key={index}
                      show={step === index + 1}
                      enter="transition-all duration-500 ease-in-out transform"
                      enterFrom={twMerge(
                        'opacity-0',
                        change === 'down' ? '-translate-x-4' : change === 'up' ? 'translate-x-4' : 'translate-x-0',
                      )}
                      enterTo="opacity-100 translate-x-0"
                      leave="transition-all duration-500 ease-in-out transform delay-100"
                      leaveFrom="opacity-50 translate-x-0"
                      leaveTo={twMerge(
                        'opacity-0',
                        change === 'down' ? 'translate-x-4' : change === 'up' ? '-translate-x-4' : 'translate-x-0',
                      )}
                      className="col-start-1 row-start-1"
                    >
                      {index + 1}
                    </Transition>
                  ))}
                </div>
                <span>/</span>
                <span>{maxSteps}</span>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <motion.span layout="position">
          <Button
            icon={<ArrowRightIcon className="pointer-events-none h-5 w-5" />}
            className={twMerge(
              'relative flex aspect-square w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-white/30 text-current ring-1 ring-current backdrop-blur backdrop-brightness-125 transition-all duration-300 aria-disabled:pointer-events-none aria-disabled:opacity-40 aria-disabled:focus-visible:ring-0',
              showBackButton ? 'rotate-0' : 'rotate-90',
            )}
            onClick={handleClickNext}
          />
        </motion.span>
      </LayoutGroup>
    </motion.div>
  )
}
