'use client'

import { createActorContext } from '@xstate/react'
import { assign, createMachine } from 'xstate'

type Direction = 'up' | 'down'

const stepperMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QGUDGAnA9gG28gLmAA5FjoB0Bxp6AxGlrgAT6ZOyFEDaADALqJQRTLACW+UZgB2gkAA9EAFgAcy8gEZl6gKwqVAZm09t6xQBoQAT0T7N5Hop76AnD3Xr9AdmUA2HwF9-CwYcPE4aSnCyWgBhAAsAQykYdk5eASQQYTEJaVkFBABafWNyH20XT3VnbV1NC2sEbU9tDT0fKt8AJmdNQOCMUKoSMkjqaPiklI5iLnUMoRFxSRlMgsLlT3ISjvUHE0VPR2cGxCOu8haeB0UTPx8XfpAQ3GGIt+iAVSIIBMImADuoikEEwAPYogAXmB0rJsss8mtEM59GpbBUVPcup4eqcms40dcfDoakYCV0ni8wuMKB9afgEuh8PRBsxWEwpGA5PhUrN+HClrlVqACpoePY3JsvLUcb1tHjFCpyD0Sjwuj4es0SopKazqSN6TSxkRYCzGNgWGwiOgwAA3SQAV1gvO4-My8KF+Rszi2iq6ym0WmUKP0jh8eJ8igujm8in0ka6Jm8uvNdON704pqplo5XJ5M1dCyygpWXqaoe2znKtmDPSJeO8zjKtc85Sq6k8bhTQyihoNlD1wKgtFh7pLiJFiC6hku-qjxODmy6OjxzVaHWUKmcXR4Aer3devfToypQ5H8wFOVLSIQXUcEqrnc8VXK2PMVkQfi2qL9AZ4PgJTwD31DMjU+KRgWWBJsChSBaAASQgiRoNgl0aFHRYrwneREEKdx1HIAM3xMbQCWURMujxGt7EcFw3A8bx7mAtMADkEntKA-kwCgADVRDEAAjbAwFoAAJUQIDADl2NETjWHQDDiyw4UcIQVwfErNUeh9RNNk8PFtMIzcVGxZw-WqAIgmePVWJkuTuPIcSIEkqR6DiMFpI4riFLdTCERU0Vrn0chtB8TdPH0adnBDd9GkMYLUXijUNVUHggKsqk0zTcDIOQmDoQgEdfKU-yyzvVpvB4Alrgio43Eoj9bxcQjanUACPEUMKekCKypEwST4EyTLe0vUqb0KRwCOrH1qlqRR6kajsWpMVw7giyNI2Yo86VGz1xvisoKhm0l5uUPE9laQwTD3HwnFumotqNLKGSZXbr0nW9xTceM1QcIjSPUBUqnsWaqijf8jHUR7+2e4hBr8vaPuxAjrk0HFO1MNwqwbULLjS3dri6bE7wpDKbO27bB2SN7sIKONFDKapjF8CKO18WKlEOELQdMHdyi7MnUwpsCIKQ0QUIKmmAsQcU71UDxlFDW7dCjfTGqi5aO1I6c-UMaGIjYrz5KlssAzRVwieinFA2fc7tAuImEy-JWdUFnsjUN2TvPIPjBOEk2bxRDScWJeNt06zYOaapsQ6jTQO13AN9dGT37IoJyXIDj6UUuhx-WfZmAzVxp5tlslQtuYybh6-wgA */
    tsTypes: {} as import('./StepperContext.typegen').Typegen0,
    id: 'ScrollStepper',
    states: {
      Stepper: {
        initial: 'Uninitialized',

        states: {
          Start: {
            on: {
              'Scroll to next step': {
                target: 'Scrolling',
                actions: 'Increment scroll target',
              },
            },
          },

          Steps: {
            on: {
              'Scroll to previous step': {
                target: 'Scrolling',
                actions: 'Decrement scroll target',
              },
              'Scroll to next step': {
                target: 'Scrolling',
                actions: 'Increment scroll target',
              },
            },
          },

          Scrolling: {
            entry: ['Update scroll position', 'Reset scroll target'],
            always: [
              {
                target: 'Start',
                cond: 'Step is zero',
              },
              {
                target: 'Steps',
              },
            ],
          },

          Uninitialized: {
            on: {
              'Initialize stepper': {
                target: 'Start',
                cond: 'Initialized with valid values',
                actions: 'Set initial values',
              },
            },

            always: {
              target: 'Start',
              cond: 'Previously configured',
              internal: true,
            },
          },
        },

        on: {
          'Scroll to step': {
            target: '.Scrolling',
            actions: 'Set scroll target',
          },

          'Change step': [
            {
              target: '.Start',
              actions: 'Set step',
              cond: 'Step is zero',
              internal: false,
            },
            {
              target: '.Steps',
              actions: 'Set step',
              internal: false,
            },
          ],

          'Update window size': {
            target: 'Stepper',
            internal: true,
            actions: 'Set window size',
          },
        },

        invoke: {
          src: 'Watch window size',
          id: 'watchWindowSize',
        },
      },

      Navigator: {
        initial: 'Hidden',
        states: {
          Visible: {
            on: {
              'Hide navigator': {
                target: 'Hidden',
              },
            },
          },
          Hidden: {
            on: {
              'Show navigator': {
                target: 'Visible',
              },
            },
          },
        },
      },
    },
    schema: {
      context: {} as {
        step: number
        scrollTarget: number | null
        maxSteps: number
        change: Direction | null
        windowSize: [number, number]
      },
      events: {} as
        | { type: 'Initialize stepper'; maxSteps: number; step: number }
        | { type: 'Change step'; step: number }
        | { type: 'Scroll to step'; step: number }
        | { type: 'Scroll to next step' }
        | { type: 'Scroll to previous step' }
        | { type: 'Show navigator' }
        | { type: 'Hide navigator' }
        | { type: 'Update window size'; windowSize: [number, number] },
    },
    context: {
      step: 0,
      scrollTarget: null,
      maxSteps: 1,
      change: null,
      windowSize: [1600, 1600],
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
    type: 'parallel',
  },
  {
    guards: {
      'Step is zero': (ctx, evt) => ('step' in evt ? evt.step === 0 : ctx.step === 0),
      'Initialized with valid values': (_ctx, { maxSteps }) => maxSteps > 0,
      'Previously configured': ({ maxSteps }) => maxSteps > 1,
    },
    actions: {
      'Decrement scroll target': assign({
        scrollTarget: ({ step }) => step - 1,
        change: 'down',
      }),
      'Increment scroll target': assign({
        scrollTarget: ({ step }) => step + 1,
        change: 'up',
      }),
      'Set initial values': assign((_ctx, { maxSteps, step }) => ({ maxSteps, step })),
      'Set step': assign((ctx, { step }) => ({
        step,
        change: ((ctx?.step || 0) > step ? 'down' : 'up') as Direction,
      })),
      'Set scroll target': assign((ctx, { step }) => ({
        scrollTarget: step,
        change: ((ctx?.step || 0) > step ? 'down' : 'up') as Direction,
      })),
      'Update scroll position': ({ maxSteps, scrollTarget }) => {
        const stepIndex = Math.min(Math.max(scrollTarget || 1, 1), maxSteps)
        const stepEl = document.querySelector<HTMLDivElement>(`[data-scroll-step="${stepIndex}"]`)

        if (!stepEl || scrollTarget === null) {
          return
        }

        if (scrollTarget > maxSteps) {
          let nextSection = stepEl?.closest('section')?.nextElementSibling

          if (nextSection?.tagName !== 'SECTION') {
            nextSection = stepEl?.closest('section')?.parentElement?.nextElementSibling
          }

          nextSection?.scrollIntoView({ behavior: 'smooth', block: 'start' })
          return
        }

        switch (scrollTarget) {
          case 0:
            stepEl?.closest('section')?.scrollIntoView({ behavior: 'smooth', block: 'start' })
            break
          case 1:
            stepEl?.parentElement?.scrollIntoView({ behavior: 'smooth' })
            break
          default:
            stepEl?.scrollIntoView({ behavior: 'smooth' })
            break
        }
      },
      'Reset scroll target': assign({ scrollTarget: null }),
      'Set window size': assign((_ctx, { windowSize }) => ({ windowSize })),
    },
    services: {
      'Watch window size': () => (send) => {
        const handleResize = () => {
          send({ type: 'Update window size', windowSize: [window.innerWidth, window.innerHeight] })
        }
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
      },
    },
  },
)

export const StepperContext = createActorContext(stepperMachine)

export const StepperProvider = StepperContext.Provider
