'use client'

import { useLayoutEffect, useRef, useState } from 'react'
import { useInView } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

interface StickyContainerProps {
  children: React.ReactNode
  className?: string
  stuckClassName?: string
  proximity?: number
  debug?: boolean
}

export function StickyContainer({
  children,
  className,
  stuckClassName = 'blur-md ease-out opacity-40',
  proximity = 0,
  debug = false,
}: StickyContainerProps) {
  const contentRef = useRef<HTMLDivElement>(null)
  const contentInView = useInView(contentRef)

  const [overlapping, setOverlapping] = useState(false)
  useLayoutEffect(() => {
    // Set overlapping to true if the bottom of contentRef.current is overlapping with the top of the next sibling element (if any) whenever the window is scrolled or resized.
    const handleScroll = () => {
      const heading = contentRef.current
      if (!contentInView || !heading) {
        return
      }

      const nextSibling = heading.nextElementSibling
      if (!nextSibling) {
        return
      }

      const nextSiblingRect = nextSibling.getBoundingClientRect()
      const headingRect = heading.getBoundingClientRect()

      setOverlapping(nextSiblingRect.top - proximity < headingRect.bottom)
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [contentInView, proximity])

  return (
    <div
      ref={contentRef}
      className={twMerge(
        'sticky -top-px transform-gpu',
        debug && 'outline-dashed outline-1 outline-purple-500',
        className,
      )}
    >
      <div className={twMerge('transition duration-500 transform-gpu', overlapping && stuckClassName)}>{children}</div>
    </div>
  )
}
