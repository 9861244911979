'use client'

import { useState } from 'react'
import { VolumeIcon, VolumeXIcon } from 'lucide-react'
import dynamic from 'next/dynamic'
import type { FilePlayerProps } from 'react-player/file'
import { twMerge } from 'tailwind-merge'

const ReactPlayer = dynamic(() => import('react-player/file').then((ReactPlayer) => ReactPlayer), {
  ssr: false,
})

type VideoPlayerProps = FilePlayerProps & {
  className?: string
}

export const VideoPlayer = ({ className, muted: initialMuted, ...rest }: VideoPlayerProps) => {
  const [muted, setMuted] = useState(initialMuted)
  return (
    <div className={twMerge('relative aspect-video overflow-hidden', className)}>
      {!rest.controls && typeof muted !== 'undefined' && (
        <button
          onClick={() => {
            setMuted((old) => !old)
          }}
          className="absolute bottom-0 right-0 z-100 p-4 sm:p-6 md:bottom-4 md:right-4"
        >
          {muted ? <VolumeXIcon /> : <VolumeIcon />}
        </button>
      )}
      <ReactPlayer width="100%" height="100%" muted={muted} className="scale-[1.02]" playsinline {...rest} />
    </div>
  )
}
