'use client'

import { ButtonHTMLAttributes, useRef } from 'react'
import ArrowDownIcon from '@heroicons/react/20/solid/ArrowDownIcon'
import { twMerge } from 'tailwind-merge'

export function ScrollNextButton({ className, ...props }: ButtonHTMLAttributes<HTMLButtonElement>) {
  const ref = useRef<HTMLButtonElement>(null)
  const handleClick = () => {
    const el = ref.current
    if (el) {
      const nextEl = el.nextElementSibling || el.closest('section')?.nextElementSibling
      nextEl?.scrollIntoView({ behavior: 'smooth' })
    }
  }
  return (
    <button
      ref={ref}
      className={twMerge(
        'relative flex aspect-square w-10 shrink-0 cursor-pointer items-center justify-center rounded-full text-current ring-1 ring-current aria-disabled:pointer-events-none aria-disabled:opacity-40 aria-disabled:focus-visible:ring-0',
        className,
      )}
      onClick={handleClick}
      {...props}
    >
      <ArrowDownIcon className="pointer-events-none h-5 w-5" />
    </button>
  )
}
